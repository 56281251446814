.App {
  text-align: center;
  background-color: #E8E8E8;
}
html {
  background-color: #E8E8E8;
  position: relative;
  min-height: 100%;
}
.navbar-nav{
  margin-left:auto;
  padding:10px;
}
.navbar{
  background-color: #BBBFCA;
}

a.navbar-brand {
  background-color: #808db1;
  font-weight:bold;
  font-size: 25px;
  padding:5px;
  color: white !important;
  margin-left:auto;
}

.nav-link {
  font-size: large;
  color: white !important;
  font-weight:600
}

.nav-link:hover {
  background-color: #808db1;
}

.text {
  background-color:#808db1;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}


footer {
  border-top: #BBBFCA solid 10px;
  background-color:#495464;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; 
  line-height: 50px; 
  color:white;
}

.row {
  margin:5rem;
}

.card {
  background-color: #F4F4F2 !important;
  display:flex;
  margin-top:2rem;
  flex-direction: column;
  margin-left:50px;  
  margin-right:20px;  
  text-align: left;
  
}

.project-card{
  padding:20px;
  margin-top:20px;
}
.card-title.h5 {
  background-color: #F4F4F2 !important;
  font-weight:bold;
  color:#808db1;
  font-size: 40px;
  border-bottom: solid 0.5px lightgray;
}

.avatar {
    width: 300px;
    height: 400px;
    object-fit: cover;
}
.card-deck{
margin:15px;
}

.project-cards{
  height:100%;
}
.aboutme-card {
  margin-top:2rem !important;
}

#project-title{
  font-size:20px !important;
}

a{
  color:#495464 !important
}

.project-btn{
  margin-right:5px;
}

.card-deck{
  gap:20px 0;
}

.project-deck{
  padding-bottom:20px !important;
}

.portfoliocards{
  padding:5px !important
}

@media only screen and (max-width: 575px) {
  .portfoliocards{
    padding:0 !important;
  
  }
  .card{
    margin: 15px 0 !important;
  }

  a.navbar-brand {
    margin-left:0;
  }
}

.row {
  margin-left: 20px !important;
  margin-right: 20px !important;
}